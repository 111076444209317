import { addDays, getUnixTime, startOfDay, subDays } from 'date-fns';

export const appStage = (stage: string): boolean => {
  const current = process.env.REACT_APP_STAGE || 'localhost';
  switch (stage) {
    case 'dev':
      return ['localhost', 'development'].includes(current);
    case 'live':
      return ['production'].includes(current);
    default:
      return false;
  }
}


export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const urls = {
  URL: process.env.REACT_APP_URL_BASE,
  URL_CP: process.env.REACT_APP_URL_CP,
  remoteURL: process.env.REACT_APP_REMOTE_URL,
}

export const tokenID = "templateID";

export const daysofweek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const _now = getUnixTime(new Date);
export const _1DayAgo = getUnixTime(startOfDay(subDays(new Date, 1)));
export const _1DayAway = getUnixTime(startOfDay(addDays(new Date, 1)));
export const _7DaysAgo = getUnixTime(startOfDay(subDays(new Date, 7)));
export const _7DaysAway = getUnixTime(startOfDay(addDays(new Date, 7)));
export const _14DaysAgo = getUnixTime(startOfDay(subDays(new Date, 14)));
export const _14DaysAway = getUnixTime(startOfDay(addDays(new Date, 14)));
