import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import uuid from 'react-uuid'
import numeral from "numeral";
import toast from "react-hot-toast";

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';

import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';



import { FullCompany } from "../../types/company";
import { FullQuote } from "../../types/quote";
import { FullService } from "../../types/service";

import { urls } from "../../common";
import { useUser } from "../../context/User";
import { _DaysAway, _MonthsAway, formatDate } from "../../common/dates";
import LoadingDots from "../../components/LoadingDots";
import Error from "../Errors";
import Label from "../../components/form/Label";
import Input from "../../components/form/Input";
import CustomSelect, { CustomSelectControl } from "../../components/form/CustomSelect";
import Button from "../../components/Button";
import Tooltip from "../../components/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDown, faPlus, faRefresh, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { Checkbox } from "../../components/form/Checkbox";
import TextArea from "../../components/form/Textarea";
import { useGlobal } from "../../context/globals";
import Tippy from "@tippyjs/react";

type FormProps = FullCompany & FullQuote & { user_id?: string };
type props = {
    current?: FormProps,
    company?: FullCompany,
}


const QuoteForm = ({ current, company }: props) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const { user } = useUser();
    const { setGlobal } = useGlobal();

    const [adminUsers, setAdminUsers] = useState<{ value: string, label: string }[]>();
    const navigate = useNavigate();

    const { register, handleSubmit, watch, formState: { errors }, getValues, reset, setValue, control } = useForm();

    const [services, setServices] = useState<FullService[]>([]);
    const [serviceID, setServiceID] = useState<string | undefined>(current ? current?.service_id : undefined);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function handleDragEnd(event: { active: any; over: any; }) {
        const { active, over } = event;
        if (active.id === over.id) { return; }
        const updated = quote?.options ? [...quote?.options] : [];
        const oldIndex = updated.findIndex(x => x.id === active.id);
        const newIndex = updated.findIndex(x => x.id === over.id);

        setQuote(prev => ({ ...prev, options: arrayMove(updated, oldIndex, newIndex) }));
    }


    const [quote, setQuote] = useState<FormProps | undefined>(current ? current : undefined);
    const editQuote = current ? true : false;

    const [yearlyShowCost, setYearlyShowCost] = useState<number>(0.00);
    const [monthlyShowCost, setMonthlyShowCost] = useState<number>(0.00);
    const [yearlyCost, setYearlyCost] = useState<number>(0.00);
    // const [monthlyCost, setMonthlyCost] = useState<number>(0.00);


    const [packageCost, setPackageCost] = useState<number>(0.00);
    const [additionalCost, setAdditionalCost] = useState<number>(0.00);
    const [discount, setDiscount] = useState<number>(0.00);
    const [discountPercent, setDiscountPercent] = useState<number>(0.00);

    useEffect(() => {
        if (!company) { setGlobal(prev => ({ ...prev, current: 'create-quote' })) }
        axios.get(`${urls.remoteURL}services/all`)
            .then(({ data }) => {
                setServices(data);
                setLoading(false);
            })
            .catch((e) => {
                setError(true);
            });
    }, []);



    useEffect(() => {
        axios.get(`${urls.remoteURL}admin/all`)
            .then(({ data }) => {
                setAdminUsers(data.map((v: any) => ({ label: `${v.firstname} ${v.lastname}`, value: v.id })));
                if (company?.id && !current) {
                    setQuote({
                        name: company.name,
                        contact: company.contact,
                        email: company.email,
                        tel: company.tel,
                        validDate: _MonthsAway(1),
                        user_id: user?.id
                    });
                } else if (!current) {
                    setQuote({ ...quote, validDate: _MonthsAway(1), user_id: user?.id });
                    reset();
                } else {
                    setDiscount(current?.discount_amount || 0);
                    setDiscountPercent(current?.discount_percent || 0);
                }

                setLoading(false);
            })
            .catch((e) => {
                console.error(e);
            });


    }, [company, current]);

    const onSubmit = (data: any) => {
        // setLoading(true);
        if (editQuote) {
            data.id = current?.id;
            axios.put(`${urls.remoteURL}quotes/`, { ...quote, ...data, total: yearlyCost })
                .then(({ data }) => {
                    setQuote((prev) => ({ ...prev, ...data, total: yearlyCost }));
                    toast.success('Quote saved');
                })
                .catch((err) => {
                    toast.error('Error Saving, please check you have filled everything in correctly.')
                })
                .finally(() => {
                    setLoading(false);
                });
            return;
        }

        if (company?.id) { data.company_id = company.id; } // add to company

        axios.post(`${urls.remoteURL}quotes/`, { ...quote, ...data, total: yearlyCost })
            .then(({ data }) => {
                toast.success('Quote saved')
                navigate(`/companies/${data.company_id}/quotes/${data.id}`);
            })
            .catch((err) => {
                toast.error('Error Saving, please check you have filled everything in correctly.')
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const watchAllFields = watch();

    useEffect(() => {
        if (serviceID && !quote?.service_id) {
            handleReloadServiceDefaults();
        }
    }, [serviceID])

    const handleReloadServiceDefaults = () => {
        const use_service = services.filter(service => service.id === serviceID)[0];

        setQuote({
            ...quote,
            service_id: use_service.id,
            options: use_service.options,
            travel_options: use_service.travel_options,
            site_options: use_service.site_options,
            package_options: use_service.package_options,
            payment_options: use_service.payment_options,
            additional_options: use_service.additional_options,
            commitment: !!use_service?.payment_options?.options && use_service?.payment_options?.options?.length > 0 ? use_service.payment_options.options[0].id : undefined,
            travel: use_service?.travel_options?.options?.length ? use_service.travel_options.options[0].id : undefined,
            package: use_service.package_options?.options?.length > 0 ? use_service.package_options.options[0].id : null,
            package_cost: use_service.package_options?.options?.length > 0 ? use_service.package_options.options[0].rate : null,
        });
    }

    const handleUpdateValidUntil = (days: number) => {
        setQuote((old) => ({ ...old, validDate: _DaysAway(days) }));
    }

    const updateDiscount = (e: any) => {
        if (e.target.name === 'discount_amount') {
            setValue('discount_percent', 0)
            setDiscountPercent(0);
            setDiscount(e.target.value);
        } else {
            setValue('discount_amount', 0)
            setDiscount(0);
            if (e.target.value < 100) {
                setDiscountPercent(e.target.value);
            } else {
                setDiscountPercent(99);
                setValue('discount_percent', 99)
            }
        }
    }



    const updateQuote = async () => {
        let yc = 0;
        let mc = 0;

        if (loading) { return; }



        const risk = getValues('package') || quote?.package;
        const package_cost = getValues('package_cost') || quote?.package_cost;
        const riskPremium = getValues('package_plus') || quote?.package_plus;
        const travel = getValues('travel') || quote?.travel;
        const sites = getValues('sites') || quote?.sites;
        const commitment = getValues('commitment') || quote?.commitment;
        const additional_days = getValues('additional_days') || quote?.additional_days;
        const online_courses = getValues('online_courses') || quote?.online_courses;
        const prepaid_training = getValues('prepaid_training') || quote?.prepaid_training;
        const extra_price = getValues('extra_price') || quote?.extra_price;

        if (!package_cost || package_cost === 0 || (!quote?.payment_options?.onetime && !risk)) { return; }

        if (!quote?.payment_options?.onetime) {
            const current_package = quote?.package_options?.options?.filter((x: { id: any; }) => x.id === risk)[0];
            const current_travel = quote?.travel_options?.options?.filter(x => x.id === travel)[0];
            const current_commitment = quote?.payment_options?.options?.filter((x: any) => x.id === commitment)[0] || null;

            yc = !riskPremium ? parseFloat(package_cost) : parseFloat(package_cost) + parseFloat(current_package.plus);

            if (current_travel) { yc = yc * current_travel.rate; }
            if (sites > 1) { yc += (yc * quote?.site_options.rate) * (sites - 1); }
            if (current_commitment) { yc = yc * current_commitment.rate; }
        } else {
            yc = parseFloat(package_cost);
        }

        setYearlyCost(yc);

        if (additional_days > 0) { yc += parseInt(additional_days) * quote?.additional_options.daysrate }
        if (online_courses) { yc += parseInt(quote?.additional_options.onlinerate) }
        if (prepaid_training) { yc += parseInt(quote?.additional_options.trainingrate) }

        // setMonthlyCost(yc / 12);

        yc -= discount > 0 ? discount : discountPercent > 0 ? yc * (discountPercent / 100) : 0;
        yc -= discount > 0 ? (discount / 12) : discountPercent > 0 ? mc * (discountPercent / 100) : 0;

        if (extra_price > 0) { yc += parseFloat(extra_price); }


        // discounted visible cost
        setYearlyShowCost(yc);
        setMonthlyShowCost(yc / 12);

    }

    useEffect(() => {
        updateQuote();
    }, [watchAllFields, discount, discountPercent, packageCost, additionalCost]);



    const ServiceOption = ({ option, i }: { option: any, i: number }) => {

        const {
            attributes,
            listeners,
            setNodeRef,
            transform,
            transition,
        } = useSortable({ id: option.id });


        const style = {
            transform: CSS.Translate.toString(transform),
            transition,
            height: 'auto',
        };


        return (
            <div ref={setNodeRef} style={style}
                className='mt-3 flex items-center gap-2' key={`option-${option.id}`}>
                <Input
                    type="text"
                    disabled={!!loading}
                    placeholder='Option'
                    defaultValue={option.value}
                    className={'flex-1'}
                    // {...register(`options.${i}.value`)}
                    onBlur={(x) => {
                        if (quote?.options && quote.options[i]) {
                            quote.options[i].value = x.target.value;
                        }
                        setQuote((prev) => ({ ...prev, options: quote?.options }));
                    }}
                />
                <div className="flex gap-2">
                    <Tippy content={'Header'}>
                        <div>
                            <Checkbox defaultChecked={!!quote?.options ? !!quote.options[i].header : false} id="" setValue={() => {
                                if (quote?.options && quote.options[i]) {
                                    quote.options[i].header = !quote.options[i].header;
                                }
                                setQuote({ ...quote, options: quote?.options });
                            }} />
                        </div>
                    </Tippy><Button type="button" {...attributes} {...listeners}><FontAwesomeIcon icon={faArrowsUpDown} fixedWidth className="h-5 w-5" /></Button>
                    <Button
                        type="button"
                        color="red"
                        onClick={() => {
                            quote?.options?.splice(i, 1);
                            setQuote((prev) => ({ ...prev, options: quote?.options }));
                        }}><FontAwesomeIcon icon={faXmark} fixedWidth className="h-5 w-5" /></Button>
                </div>
            </div>
        )
    }

    if (loading || !adminUsers) {
        return (
            <LoadingDots />
        )
    }

    if (error) {
        return (
            <Error fullscreen={false} />
        )
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="">
                <div className="bg-white rounded-lg shadow px-3 py-3 mx-3 mb-6 break-inside-avoid">
                    <div className="flex border-gray-200 border-b mb-2 items-center pb-2">
                        <h4 className={`flex-1 `}>Company Details</h4>
                        {!!editQuote &&
                            <div className="">
                                <Button to={'../..'}>Edit Company</Button>
                            </div>
                        }
                    </div>

                    <div className=" mb-3">
                        <Label>Company Name</Label>
                        <Input
                            type="text"
                            disabled={loading || editQuote || !!company?.id}
                            defaultValue={quote?.name}
                            {...register('name', { required: (!editQuote && !company?.id) })}
                        />
                    </div>
                    <div className=" mb-3">
                        <Label>Contact</Label>
                        <Input
                            type="text"
                            disabled={loading || editQuote || !!company?.id}
                            defaultValue={quote?.contact}
                            {...register('contact', { required: (!editQuote && !company?.id) })}
                        />
                    </div>
                    <div className=" mb-3">
                        <Label>Email</Label>
                        <Input
                            type="email"
                            disabled={loading || editQuote || !!company?.id}
                            defaultValue={quote?.email}
                            {...register('email', { required: (!editQuote && !company?.id) })}
                        />
                    </div>
                    <div className="mb-3">
                        <Label>Telephone</Label>
                        <Input
                            type="text"
                            disabled={loading || editQuote || !!company?.id}
                            defaultValue={company?.tel}
                            {...register('tel', { required: (!editQuote && !company?.id) })}
                        />
                    </div>
                    {/* <div className="mb-3">
                        <Label>Position</Label>
                        <Input
                            type="text"
                            disabled={loading || editQuote || !!company?.id}
                            defaultValue={company?.contact_position}
                            {...register('contact_position', { required: (!editQuote && !company?.id) })}
                        />
                    </div> */}
                </div>


                <div className="bg-white rounded-lg shadow px-3 py-3 mx-3 mb-6  print:hidden">
                    <h4 className={`border-gray-200 border-b mb-2`}>Service Details</h4>

                    <div className=" mb-3 flex gap-2">
                        <div className="flex-1" >
                            <Label>Service Preset</Label>
                            <CustomSelect
                                name="package"
                                defaultValue={serviceID || ''}
                                onChange={(x) => {
                                    setServiceID(x.target.value);
                                }}
                            >
                                <option key='none' value={''}>Please select service...</option>
                                {services.map((option) => (
                                    <option key={'package' + option.id} value={option.id}>{option.name}</option>
                                ))}
                            </CustomSelect>
                        </div>
                        <div className="self-end">
                            <Tooltip content="Reload Defaults">
                                <Button
                                    color="green"
                                    type="button"
                                    disabled={!serviceID}
                                    onClick={() => {
                                        handleReloadServiceDefaults();
                                    }}
                                ><FontAwesomeIcon icon={faRefresh} fixedWidth /></Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>

                {!quote?.service_id ?
                    <div className="bg-white rounded-lg shadow px-3 py-3 mx-3"><h4 className={` flex-1 text-center`}>Select Service to continue</h4></div>
                    :
                    <>
                        <div className="bg-white rounded-lg shadow px-3 py-3 mx-3 mb-6 block md:sticky top-auto md:top-1  z-10" >
                            <div className="flex border-gray-200 border-b">
                                <h4 className={` flex-1 mb-3 pb-2`}>Offer Summary for: {current?.name || getValues('name')}</h4>
                                <div className="">
                                    <div className="flex gap-2">
                                        <Button color="rhinoBlue" type="button" onClick={() => { window.print() }}>Print</Button>
                                        <Button color="green" type="submit" disabled={yearlyShowCost === 0}>{editQuote ? `Update Quote` : `Generate Quote`}</Button>
                                        {editQuote && <Link className="btn" to={`/view-quote/${quote.company_id}-${quote.id}`} type="button">View Quote</Link>}
                                    </div>
                                </div>
                            </div>
                            <div className="block md:flex">
                                <div className="flex-1 mb-3 mt-3" >
                                    <h5 className={``}>Prepared by: {adminUsers.filter(x => x.value === quote.user_id)[0].label}</h5>
                                    <h5 className={``}>Valid Until: {formatDate({ time: quote.validDate as number })}</h5>
                                    {!!quote?.sent &&
                                        <h5 className={`mb-2`}>Offer Sent: {formatDate({ time: quote.sent })}</h5>}
                                </div>
                                <div className="flex-1 mb-3 mt-3">
                                    {
                                        !quote?.payment_options?.onetime ?
                                            <>
                                                <h5 className={`text-left md:text-right`}>{company?.currency || '£'} {numeral(yearlyShowCost).format('0,0.00')} {!!quote.inc_vat && `+ VAT`} per year</h5>
                                                <h5 className={`text-left md:text-right`}>{company?.currency || '£'} {numeral(monthlyShowCost).format('0,0.00')} {!!quote.inc_vat && `+ VAT`} per month</h5>
                                            </>
                                            :
                                            <h5 className={`text-left md:text-right`}>{company?.currency || '£'} {numeral(yearlyShowCost).format('0,0.00')} {!!quote.inc_vat && `+ VAT`}</h5>
                                    }
                                </div>
                            </div>

                        </div>



                        <div className="bg-white rounded-lg shadow px-3 py-3 mx-3 mb-6">
                            <div className="flex border-gray-200 border-b">
                                <h4 className={` flex-1 mb-2`}>Options</h4>
                                <div><Button
                                    type="button"
                                    color="green"
                                    onClick={() => {
                                        const value = { value: 'New Option', id: uuid(), header: false };
                                        if (!!value.value) {
                                            const options = !quote?.options ? [value] : [...quote?.options, value];
                                            setQuote({ ...quote, options: options });
                                        }
                                    }}><FontAwesomeIcon icon={faPlus} fixedWidth /></Button>
                                </div>
                            </div>
                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={handleDragEnd}
                            >
                                <SortableContext
                                    items={quote?.options as any}
                                    strategy={verticalListSortingStrategy}
                                >
                                    {quote?.options?.map((option, i) => <ServiceOption key={option.id} option={option} i={i} />)}
                                </SortableContext>
                            </DndContext>
                        </div>

                        <div className="block md:flex gap-3 print:break-inside-avoid print:grid print:grid-cols-2">
                            {quote?.package_options?.options?.length > 0 &&
                                <div className="flex-1 bg-white rounded-lg shadow px-3 py-3 mx-3 mb-6">
                                    <div className="flex border-gray-200 border-b mb-3">
                                        <h4 className={` flex-1 mb-2`}>Package Options</h4>
                                    </div>

                                    <div className="mb-3">
                                        <CustomSelect
                                            name="package"
                                            defaultValue={quote?.package}
                                            onChange={(e) => {

                                                const cost = quote?.package_options.options.filter((x: any) => x.id === e.target.value)[0].rate;

                                                setQuote({
                                                    ...quote,
                                                    package: e.target.value,
                                                    package_cost: cost
                                                })
                                                setValue('package_cost', cost)
                                                setValue('package', e.target.value)
                                            }}
                                        >
                                            <option key='none' value={''}>Select option...</option>
                                            {quote?.package_options.options.map((option: any) => (
                                                <option key={'package' + option.id} value={option.id}>{option.value}</option>
                                            ))}
                                        </CustomSelect>

                                    </div>
                                    <Label>Package Cost</Label>
                                    <Input
                                        type='number'
                                        disabled={loading}
                                        step={"0.01"}
                                        min={0}
                                        className={'flex-1'}
                                        defaultValue={quote.package_cost}
                                        {...register('package_cost')}
                                        onChange={(e) => {
                                            setValue('package_cost', e.target.value);
                                            setPackageCost(Number(e.target.value));
                                        }}
                                    />
                                    {
                                        !!quote.package_options.plusrate &&
                                        <div className="mt-3">
                                            <Label>Premium Plus</Label>
                                            <Checkbox
                                                id="package_plus"
                                                defaultChecked={!!quote.package_plus}
                                                setValue={(value) => {
                                                    setQuote({ ...quote, package_plus: !!value })
                                                    setValue('package_plus', value)
                                                }}
                                            />
                                        </div>
                                    }

                                </div>
                            }

                            {!!quote?.payment_options?.onetime &&
                                <div className="flex-1 bg-white rounded-lg shadow px-3 py-3 mx-3 mb-6">
                                    <div className="flex border-gray-200 border-b mb-3">
                                        <h4 className={` flex-1 mb-2`}>One Time Payment</h4>
                                    </div>
                                    <Label>Package Cost</Label>
                                    <Input
                                        type='number'
                                        disabled={loading}
                                        step={"0.01"}
                                        min={0}
                                        className={'flex-1'}
                                        defaultValue={quote.package_cost}
                                        {...register('package_cost')}
                                        onChange={(e) => {
                                            setValue('package_cost', e.target.value);
                                            setPackageCost(Number(e.target.value));
                                        }}
                                    />
                                </div>


                            }

                            {quote?.travel_options?.options &&
                                <div className="flex-1 bg-white rounded-lg shadow px-3 py-3 mx-3 mb-6">
                                    <div className="flex border-gray-200 border-b mb-3">
                                        <h4 className={` flex-1 mb-2`}>Travel Options</h4>
                                    </div>

                                    <div className="mb-3">
                                        <Label>Travel premiums</Label>
                                        <CustomSelect
                                            name="travel"
                                            defaultValue={quote?.package}
                                            onChange={(e) => {
                                                setQuote({ ...quote, travel: e.target.value });
                                                setValue('travel', e.target.value);
                                            }}
                                        >
                                            <option key='none' value={''}>Select option...</option>
                                            {quote?.travel_options.options.map((option: any) => (
                                                <option key={'travel_' + option.id} value={option.id}>{option.value}</option>
                                            ))}
                                        </CustomSelect>
                                    </div>
                                    <div className="mb-3">
                                        <Label>Multi-site premium</Label>

                                        <CustomSelect
                                            name="travel"
                                            defaultValue={quote?.package}
                                            onChange={(e) => {
                                                setQuote({ ...quote, sites: Number(e.target.value) })
                                                setValue('sites', e.target.value)
                                            }}
                                        >
                                            <option key='none' value={''}>Select option...</option>
                                            {Array.from(Array(parseInt(quote.site_options.max) || 20), (x, i) => ({ value: i + 1, label: i + 1 })).map((option: any) => (
                                                <option key={'sites' + option.value} value={option.value}>{option.label}</option>
                                            ))}
                                        </CustomSelect>
                                    </div>
                                </div>
                            }


                        </div>

                        <div className="block md:flex gap-3 print:break-inside-avoid print:grid print:grid-cols-2">
                            {!quote?.payment_options?.onetime &&
                                <div className="flex-1 bg-white rounded-lg shadow px-3 py-3 mx-3 mb-6">
                                    <div className="flex border-gray-200 border-b mb-3">
                                        <h4 className={` flex-1 mb-2`}>Payment Options</h4>
                                    </div>


                                    <>
                                        <div className="mb-3">
                                            <Label>Payment terms</Label>
                                            <CustomSelect
                                                defaultValue={quote?.terms || 'Annually'}
                                                {...register('terms', { required: true })}
                                            >
                                                {/* <option key='none' value={''}>Select option...</option> */}
                                                {[
                                                    { label: 'Monthly', value: 'Monthly' },
                                                    // { label: 'Quarterly', value: 'Quarterly' },
                                                    { label: 'Annually', value: 'Annually' },
                                                ].map((option: any) => (
                                                    <option key={'travel' + option.value} value={option.value}>{option.label}</option>
                                                ))}
                                            </CustomSelect>
                                        </div>

                                        <div className="mb-3">
                                            {!!quote?.payment_options.options ?

                                                <>
                                                    <Label>Commitment</Label>
                                                    <CustomSelect
                                                        name="commitment"
                                                        defaultValue={quote.commitment || quote?.payment_options.options[0].id}
                                                        onChange={(e) => {
                                                            setQuote({ ...quote, commitment: e.target.value });
                                                            setValue('commitment', e.target.value);
                                                        }}
                                                    >
                                                        <option key='none' value={''}>Select option...</option>
                                                        {quote?.payment_options.options.map((option: any) => (
                                                            <option key={'travel' + option.id} value={option.id}>{option.value}</option>
                                                        ))}
                                                    </CustomSelect>
                                                </>
                                                : <p className="italic">No Payment Options setup</p>}
                                        </div>
                                    </>
                                </div>
                            }

                            {quote.additional_options.days > 0 &&

                                <div className="flex-1 bg-white rounded-lg shadow px-3 py-3 mx-3 mb-6">
                                    <div className="flex border-gray-200 border-b mb-3">
                                        <h4 className={` flex-1 mb-2`}>Additional Assurance</h4>
                                    </div>


                                    <div className="mb-3">
                                        <Label>Additional monitoring days</Label>
                                        <CustomSelect
                                            name="additional_days"
                                            // defaultValue={quote?.commitment || quote?.payment_options?.options[0]?.id || 0}
                                            onChange={(e) => {
                                                setQuote({ ...quote, additional_days: Number(e.target.value) });
                                                setValue('additional_days', e.target.value);
                                            }}
                                        >
                                            <option key='none' value={''}>Select option...</option>
                                            {Array.from(Array(parseInt(quote.additional_options.days) || 20), (x, i) => ({ value: i + 1, label: i + 1 })).map((option: any) => (
                                                <option key={'travel' + option.value} value={option.value}>{option.label}</option>
                                            ))}
                                        </CustomSelect>
                                    </div>

                                    <div className="mb-3">
                                        <Label>Day Rate</Label>
                                        <Input
                                            type='number'
                                            disabled={loading}
                                            step={"0.01"}
                                            min={0}
                                            className={'flex-1'}
                                            defaultValue={quote.additional_options.daysrate}
                                            onChange={(e) => {
                                                // setValue('package_cost', e.target.value);
                                                setQuote((prev) => ({ ...prev, additional_options: { ...prev?.additional_options, daysrate: e.target.value } }));
                                            }}
                                        />
                                    </div>

                                    {
                                        quote.additional_options.onlinerate > 0 &&
                                        <div className="mb-3">
                                            <Label>Online Courses</Label>
                                            <Checkbox
                                                id="online_courses"
                                                defaultChecked={!!quote.online_courses}
                                                setValue={(e) => {
                                                    setQuote({ ...quote, online_courses: !!e })
                                                    setValue('online_courses', e)
                                                }}
                                            />
                                        </div>
                                    }

                                    {
                                        quote.additional_options.trainingrate > 0 &&
                                        <div className="mb-3">
                                            <Label>Online Prepaid training courses</Label>
                                            <Checkbox
                                                id={'prepaid_training'}
                                                defaultChecked={!!quote.prepaid_training}
                                                // {...register('online_courses')}
                                                setValue={(e) => {
                                                    setQuote({ ...quote, prepaid_training: !!e })
                                                    setValue('prepaid_training', e)
                                                }}
                                            />
                                        </div>
                                    }


                                </div>
                            }



                        </div>


                        <div className="block md:flex gap-3 print:break-inside-avoid print:grid print:grid-cols-2">
                            <div className="flex-1 bg-white rounded-lg shadow px-3 py-3 mx-3 mb-6">
                                <div className="flex border-gray-200 border-b mb-3">
                                    <h4 className={` flex-1 mb-2`}>Offer Options</h4>
                                </div>
                                <div className="mb-3">
                                    <Label>Prepared by:</Label>
                                    <CustomSelect
                                        name="additional_days"
                                        defaultValue={quote?.user_id || user?.id}
                                        onChange={(e) => {
                                            setQuote({ ...quote, user_id: e.target.value });
                                            setValue('user_id', e.target.value);
                                        }}
                                    >
                                        <option key='none' value={''}>Select option...</option>
                                        {adminUsers.map((option: any) => (
                                            <option key={'user_id' + option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </CustomSelect>
                                </div>

                                <div className="mb-3">
                                    <Label>Change Valid Date</Label>
                                    <CustomSelect
                                        name="additional_days"
                                        defaultValue={30}
                                        onChange={(e) => {
                                            handleUpdateValidUntil(Number(e.target.value));
                                        }}
                                    >
                                        <option key='none' value={''}>Select option...</option>
                                        {[
                                            { label: '1 Week', value: 7 },
                                            { label: '2 Weeks', value: 14 },
                                            { label: '3 Weeks', value: 21 },
                                            { label: '1 Month', value: 30 },
                                            { label: '2 Month', value: 60 }
                                        ].map((option: any) => (
                                            <option key={'updateValid' + option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </CustomSelect>
                                </div>
                            </div>



                            <div className="flex-1 bg-white rounded-lg shadow px-3 py-3 mx-3 mb-6">
                                <div className="flex border-gray-200 border-b mb-3">
                                    <h4 className={` flex-1 mb-2`}>Signature/Directors Details</h4>
                                </div>

                                <div className="mb-3">
                                    <Label>Director</Label>
                                    <Input
                                        type='text'
                                        disabled={loading}
                                        className={'flex-1'}
                                        defaultValue={quote.director_name}
                                        {...register('director_name')}
                                    />
                                </div>

                                <div className="mb-3">
                                    <Label>Signature</Label>
                                    <Input
                                        type='text'
                                        disabled={loading}
                                        className={'flex-1'}
                                        defaultValue={quote.signature_name}
                                        {...register('signature_name')}
                                    />
                                </div>

                                {quote.signature ? <div className="text-center "> <img src={quote.signature} className="border p-2 mx-auto" /></div> : <p>Not signed yet.</p>}
                            </div>

                        </div>

                        <div className="block md:flex gap-3 print:break-inside-avoid print:grid print:grid-cols-2">

                            <div className="flex-1 bg-white rounded-lg shadow px-3 py-3 mx-3 mb-6 ">
                                <div className="flex border-gray-200 border-b mb-3">
                                    <h4 className={` flex-1 mb-2`}>Invoicing</h4>
                                </div>

                                <div className="mb-3">
                                    <Checkbox
                                        id="inc_vat"
                                        label="Include VAT"
                                        defaultChecked={!!quote?.inc_vat}
                                        setValue={() => {
                                            setQuote({ ...quote, inc_vat: !quote?.inc_vat });
                                        }} />
                                </div>


                                <div className="mb-3">
                                    <Label>Name</Label>
                                    <Input
                                        type='text'
                                        disabled={loading}
                                        className={'flex-1'}
                                        defaultValue={quote.billing_name}
                                        {...register('billing_name')}
                                    />
                                </div>
                                <div className="mb-3">
                                    <Label>Email</Label>
                                    <Input
                                        type='email'
                                        disabled={loading}
                                        className={'flex-1'}
                                        defaultValue={quote.billing_email}
                                        {...register('billing_email')}
                                    />
                                </div>
                                <div>
                                    <Label>Telephone</Label>
                                    <Input
                                        type='text'
                                        disabled={loading}
                                        className={'flex-1'}
                                        defaultValue={quote.billing_telephone}
                                        {...register('billing_telephone')}
                                    />
                                </div>
                                <div>
                                    <Label>Position</Label>
                                    <Input
                                        type='text'
                                        disabled={loading}
                                        className={'flex-1'}
                                        defaultValue={quote.billing_position}
                                        {...register('billing_position')}
                                    />
                                </div>
                            </div>

                            <div className="flex-1 bg-white rounded-lg shadow px-3 py-3 mx-3 mb-6">
                                <div className="flex border-gray-200 border-b mb-3">
                                    <h4 className={` flex-1 mb-2`}>Additional Notes</h4>
                                </div>

                                <div className="mb-3">
                                    <TextArea
                                        rows={4}
                                        defaultValue={quote.notes}
                                        {...register('notes')}
                                    />

                                </div>
                                <div>
                                    <Label>Addition Costs {company?.currency}</Label>
                                    <Input
                                        type='number'
                                        disabled={loading}
                                        step={"0.01"}
                                        min={0}
                                        className={'flex-1'}
                                        defaultValue={quote.extra_price}
                                        {...register('extra_price')}
                                        onChange={(e) => {
                                            setAdditionalCost(Number(e.target.value));
                                            setValue('extra_price', e.target.value)
                                        }}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="block md:flex gap-3 print:break-inside-avoid print:grid print:grid-cols-2">

                            <div className="flex-1 bg-white rounded-lg shadow px-3 py-3 mx-3 mb-6">
                                <div className="flex border-gray-200 border-b mb-3">
                                    <h4 className={` flex-1 mb-2`}>Extra Cost</h4>
                                </div>

                                <TextArea
                                    rows={4}
                                    defaultValue={quote.extra_cost_notes}
                                    onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }}
                                    {...register('extra_cost_notes')}
                                />

                                <Label>Extra Cost {company?.currency}</Label>
                                <Input
                                    type='number'
                                    disabled={loading}
                                    step={"0.01"}
                                    min={0}
                                    className={'flex-1'}
                                    defaultValue={quote.extra_cost}
                                    {...register('extra_cost')}
                                />
                            </div>



                            <div className="flex-1 bg-white rounded-lg shadow px-3 py-3 mx-3 mb-6">
                                <div className="flex border-gray-200 border-b mb-3">
                                    <h4 className={` flex-1 mb-2`}>Special Discount</h4>
                                </div>

                                <div className=" mb-3">
                                    <Label>Reason</Label>
                                    <Input
                                        type="text"
                                        disabled={loading}
                                        defaultValue={quote.discount_reason}
                                        {...register('discount_reason')}
                                    />
                                </div>
                                <div className=" mb-3">
                                    <Label>Amount ({company?.currency})</Label>
                                    <Input
                                        type="number"
                                        disabled={loading}
                                        defaultValue={quote.discount_amount}
                                        {...register('discount_amount')}
                                        onChange={updateDiscount}
                                    />
                                </div>
                                <div className="mb-3">
                                    <Label>Amount (%)</Label>
                                    <Input
                                        type="number"
                                        disabled={loading}
                                        defaultValue={quote.discount_percent}
                                        {...register('discount_percent')}
                                        onChange={updateDiscount}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="block md:flex gap-3 print:break-inside-avoid print:grid print:grid-cols-2">

                            <div className="flex-1 bg-white rounded-lg shadow px-3 py-3 mx-3 mb-6 space-y-2">
                                <div className="flex border-gray-200 border-b">
                                    <h4 className={` flex-1 mb-2`}>Invoice Terms</h4>
                                </div>

                                <div>
                                    <Label>Contracts</Label>
                                    <Input
                                        type="text"
                                        disabled={loading}
                                        className={'flex-1'}
                                        placeholder="Contracts - 30 days from invoicing date"
                                        defaultValue={quote.terms_contract}
                                        {...register('terms_contract')}
                                    />
                                </div>
                                <div>
                                    <Label>FRA</Label>
                                    <Input
                                        type="text"
                                        disabled={loading}
                                        className={'flex-1'}
                                        placeholder="FRA - 7 days from invoicing date - to be noted that paperwork will not be released until payment is received and confirmed"
                                        defaultValue={quote.terms_fra}
                                        {...register('terms_fra')}
                                    />
                                </div>
                                <div>
                                    <Label>Training</Label>
                                    <Input
                                        type="text"
                                        disabled={loading}
                                        className={'flex-1'}
                                        placeholder="Training - Non-refundable 50% payment on receipt of invoice with remaining balance to be paid by course date"
                                        defaultValue={quote.terms_training}
                                        {...register('terms_training')}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </form >
    )
}

export default QuoteForm
